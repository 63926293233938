<!-- eslint-disable max-len -->
<template>
  <section
    id="sign-up-form"
    class="contact-form"
  >
    <div
      id="salesforce-form"
      class="container"
    >
      <h4 class="form-title">
        Great! <span>We just need a bit more&nbsp;information.</span>
      </h4>
      <div class="form-title-alt">
        <h4 class="form-title">
          Unlock your quote today!
        </h4>
        <p><em>We never sell or give your information to a third party</em></p>
      </div>
      <form>
        <div class="form-content">
          <div class="form-group">
            <label for="firstName">First name</label>
            <input
              id="firstName"
              v-model="formData.firstName"
              type="text"
              name="firstname"
              required
              :class="{ 'has-error': errors.firstName }"
            >
          </div>

          <div class="form-group">
            <label for="lastName">Last name</label>
            <input
              id="lastName"
              v-model="formData.lastName"
              type="text"
              name="lastname"
              required
              :class="{ 'has-error': errors.lastName }"
            >
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input
              id="email"
              v-model="formData.email"
              type="email"
              name="email"
              required
              :class="{ 'has-error': errors.email }"
            >
          </div>

          <div class="form-group">
            <label for="phone">Phone Number</label>
            <input
              id="phone"
              v-model="formData.phone"
              type="tel"
              name="phone1"
              title="(123-456-7890)"
              pattern="\d{3}[\-]\d{3}[\-]\d{4}"
              required
              :class="{ 'has-error': errors.phone || !correctAreaCode }"
              @input="validatePhoneNumber"
            >
          </div>
        </div>

        <p
          class="form-error"
          :class="{ active: $v.$anyError || !correctAreaCode }"
        >
          Please answer all quiz questions and enter the required information
        </p>
      </form>

      <div class="cta-block">
        <div class="cta-wrapper">
          <button
            class="btn"
            type="submit"
            @click.prevent="submitForm"
          >
            Submit
          </button>
          <small class="cta-fine-print">
            By clicking "Submit", you agree to Truehold's
            <a href="https://www.truehold.com/terms"><u>Terms of Service</u></a>
            and
            <a href="https://www.truehold.com/privacy"><u>Privacy Policy</u></a>.
          </small>
        </div>
      </div>

      <div
        v-if="isSubmitting"
        class="quiz-loading"
      >
        <div class="quiz-loading-content">
          <h3 class="quiz-loading-title">
            Submitting your information to an&nbsp;agent
          </h3>

          <img
            :src="loadingAnim"
            alt="loading animation"
          >
        </div>
      </div>
    </div>
    <!-- iframe that Salesforce uses to submit the form (Prevents redirect) -->
    <iframe
      id="jla"
      name="jla"
      src="about:blank"
      style="display:none"
    />
  </section>
</template>

<script>
import {
  required,
  email as emailValidation,
  minLength,
  maxLength,
  alpha,
} from 'vuelidate/lib/validators'
import UScodes from '../json/area-codes.json'
import loadingAnim from '../images/loading_anim.gif'

export default {
  props: {
    addressObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
      },
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
      },
      isSubmitting: false,
      loadingAnim,
      resultData: {},
      postBody: { },
      areaCodes: UScodes,
      correctAreaCode: true,
    }
  },
  validations() {
    return {
      formData: {
        email: { emailValidation, required, maxLength: maxLength(80) },
        firstName: { required, maxLength: maxLength(40), alpha },
        lastName: { required, maxLength: maxLength(80), alpha },
        phone: {
          required,
          maxLength: maxLength(12),
          minLength: minLength(12),
        },
      },
    }
  },
  computed: {
    quizResults() {
      return this.$store.getters.RESULTS
    },
  },

  methods: {
    validatePhoneNumber() {
      const phoneInput = this.formData.phone
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      if (phoneInput[0].length === 3) {
        this.correctAreaCode = this.areaCodes.hasOwnProperty(phoneInput[1])
      }
      this.formData.phone = !phoneInput[2]
        ? phoneInput[1]
        : `${phoneInput[1]}-${phoneInput[2]}${
          phoneInput[3] ? `-${phoneInput[3]}` : ''
        }`
    },
    clearErrors(keys) {
      keys.forEach(key => {
        this.errors[key] = false
      })
    },
    validateForm() {
      this.$v.$touch()

      const { formData } = this

      const keys = Object.keys(formData)
      this.clearErrors(keys)
      if (this.$v.$anyError || !this.correctAreaCode) {
      // eslint-disable-next-line no-restricted-syntax
        keys.forEach(key => {
          if (this.$v.formData[key] && this.$v.formData[key].$anyError) {
            this.errors[key] = true
          }
          if (key == 'phone' && !this.correctAreaCode) {
            this.errors[key] = true
          }
        })
        return false
      }
      return true
    },
    async submitForm() {
      this.createFormPost()
      if (this.validateForm()) {
        this.isSubmitting = true

        window.dataLayer.push({
          event: 'Submit',
          category: 'submit',
          gaEventAction: 'SubmitApplication',
          gaEventCategory: 'thesis-page-interaction',
          action: 'lead',
        })

        const form = document.createElement('form')
        form.method = 'POST'
        form.action = 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
        document.body.appendChild(form)
        const keys = Object.keys(this.postBody)
        keys.forEach(key => {
          const input = document.createElement('input')
          input.type = 'hidden'
          input.name = key
          input.value = this.postBody[key]
          form.appendChild(input)
        })
        // sets form to to iframe to prevent redirect
        form.setAttribute('target', 'jla')
        form.submit()
        setTimeout(() => {
          window.location.href = 'http://try.truehold.com/confirmation/'
        }, 3000)
      }
    },
    createFormPost() {
      /** ****
      //
      // Maps the quiz questions to the Salesforce
      //  fields and adds them to the post body.
      //
      ****** */
      const simpleResults = this.quizResults.slice(1)

      simpleResults.forEach(element => {
        if (element.label) {
          switch (element.label) {
            case 'What is the estimated value of the&nbsp;home?':
              this.postBody.Owner_Estimated_Home_Value__c = element.content
              break
            case 'Choose one of the following that best represents your relationship to the&nbsp;home:':
            case "Do you own the home you are<br class='d-sm-up'> inquiring&nbsp;about?":
              this.postBody.Relationship_to_the_Home__c = this.setRelationshipToHome(element.content)
              break
            case 'How long do you or the owner intend to stay in the&nbsp;home?':
              this.postBody.Intention_to_Stay_Quiz__c = element.content
              break
            case 'Do you currently have renters in the home?':
              this.postBody.Renter_Occupied__c = element.content.includes('Yes') ? 'True' : 'False'
              break
            case 'In addition to home concierge and property maintenance, we also offer additional&nbsp;services.':
              this.postBody.Services_Interest__c = this.setServicesInterest(element.content)
              break
            case 'Do you have any other homes in your&nbsp;portfolio?':
              this.postBody.Additional_Homes_Owned__c = element.content.includes("I don't have") ? '0' : element.content
              break
            case "What is your estimated monthly<br class='d-sm-up'> household&nbsp;income":
              this.postBody.Estimated_Monthly_Household_Income__c = element.content
              break
            case "What is their current<br class='d-sm-up'> monthly&nbsp;rent?":
              this.postBody.Current_Monthly_Rent__c = element.content
              break
            default:
              console.error('No match found for label', element.label)
              break
          }
        }
      })

      this.postBody.first_name = this.formData.firstName
      this.postBody.last_name = this.formData.lastName
      this.postBody.email = this.formData.email
      this.postBody.phone = this.formData.phone
      this.setAddressValues()
      this.postBody.UnBounce_Address__c = this.addressObject.formatted_address
      this.postBody.oid = '00D8b000002ACav' // salesforce org id

      const params = new URLSearchParams(window.location.search)

      this.postBody.UTM_Source__c = params.get('utm_source')
      this.postBody.UTM_Term__c = params.get('utm_term')
      this.postBody.UTM_Medium__c = params.get('utm_medium')
      this.postBody.UTM_Campaign__c = params.get('utm_campaign')
      this.postBody.UTM_Content__c = params.get('utm_content')
    },

    setRelationshipToHome(value) {
      if (value.includes('YES, I live')) {
        return 'Owner resident'
      } if (value.includes('YES, I rent')) {
        return 'Investor'
      } if (value.includes('currently renting')) {
        return 'Renter'
      } if (value.includes('family member owns')) {
        return 'Relative of owner'
      } if (value.includes('listing agent')) {
        return 'Listing agent'
      }

      return "Couldn't find a match"
    },
    setServicesInterest(value) {
      if (value.includes('not interested')) {
        return 'Not interested'
      }
      return value.split('(')[0].trim()
    },
    setAddressValues() {
      this.addressObject.address_components.forEach(component => {
        const componentType = component.types[0]
        switch (componentType) {
          case 'street_number':
            this.postBody.Unbounce_Street_Number__c = component.long_name
            break
          case 'route':
            this.postBody.Unbounce_Street_Name__c = component.long_name
            break
          case 'locality':
            this.postBody.city = component.long_name
            break
          case 'administrative_area_level_1':
            this.postBody.state_code = component.short_name
            break
          case 'postal_code':
            this.postBody.zip = component.long_name
            break
          case 'country':
            this.postBody.country_code = component.short_name
            break
          default:
            // eslint-disable-next-line no-console
            console.error('No match found for component type', componentType)
            break
        }
      })
    },
  },
}
</script>
