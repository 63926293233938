<template>
  <ThesisForm>
    <template #headerSlot>
      <div
        id="quiz-start"
        ref="quizStart"
      />
    </template>
    <template #loaderView>
      <SignupForm :addressObject="addressObject" />
    </template>
  </ThesisForm>
</template>

<script>
import SignupForm from './components/SignupForm.vue'

export default {
  components: {
    SignupForm,
  },
  data() {
    return {
      addressObject: {},
      autocomplete: null,
      isAddress: false,
      addressLength: 0,
      // gaData: window.dataLayer,
    }
  },
  computed: {
    dataLayerLength() {
      return this.gaData.length
    },
  },
  watch: {
    isAddress(value) {
      document.querySelector('.question-0 #submit').disabled = !value
    },
    // Leaving in case TrueHold wants to duplicate GA events with their custom naming.
    //
    // gaData: function(value){
    //   let tempVal = window.dataLayer[value.length-1]

    //   if(tempVal && tempVal.gaEventAction !== undefined){
    //     switch(tempVal.gaEventAction){
    //     case "quiz.q0.loaded":
    //       window.dataLayer.push({
    //         event: 'QuestionLoaded',
    //         eventAction : 'Q0-Loaded',
    //         eventCategory : 'quiz'
    //       })
    //       break;
    //     case "quiz.q1.loaded":
    //       window.dataLayer.push({
    //         event: 'QuestionLoaded',
    //         eventAction : 'Q1-Loaded',
    //         eventCategory : 'quiz'
    //       })
    //       break;
    //     case "quiz.q2.loaded":
    //       window.dataLayer.push({
    //         event: 'QuestionLoaded',
    //         eventAction : 'Q2-Loaded',
    //         eventCategory : 'quiz'
    //       })
    //       break;
    //     case "quiz.q3.loaded":
    //       window.dataLayer.push({
    //         event: 'QuestionLoaded',
    //         eventAction : 'Q3-Loaded',
    //         eventCategory : 'quiz'
    //       })
    //       break;
    //     case "quiz.q4.loaded":
    //       window.dataLayer.push({
    //         event: 'QuestionLoaded',
    //         eventAction : 'Q4-Loaded',
    //         eventCategory : 'quiz'
    //       })
    //       break;
    //     case "quiz.q5.loaded":
    //       window.dataLayer.push({
    //         event: 'QuestionLoaded',
    //         eventAction : 'Q5-Loaded',
    //         eventCategory : 'quiz'
    //       })
    //       break;
    //     case "Q1":
    //       window.dataLayer.push({
    //         event: 'QuestionSubmit',
    //         eventAction : 'Q0-submit',
    //         eventCategory : 'quiz',
    //         eventLabel : tempVal.gaEventLabel
    //       })
    //       break;
    //     case "Q2":
    //       window.dataLayer.push({
    //         event: 'QuestionSubmit',
    //         eventAction : 'Q1-submit',
    //         eventCategory : 'quiz',
    //         eventLabel : tempVal.gaEventLabel
    //       })
    //       break;
    //     case "Q3":
    //       window.dataLayer.push({
    //         event: 'QuestionSubmit',
    //         eventAction : 'Q2-submit',
    //         eventCategory : 'quiz',
    //         eventLabel : tempVal.gaEventLabel
    //       })
    //       break;
    //     case "Q4":
    //       window.dataLayer.push({
    //         event: 'QuestionSubmit',
    //         eventAction : 'Q3-submit',
    //         eventCategory : 'quiz',
    //         eventLabel : tempVal.gaEventLabel
    //       })
    //       break;
    //     case "Q5":
    //       window.dataLayer.push({
    //         event: 'QuestionSubmit',
    //         eventAction : 'Q4-submit',
    //         eventCategory: 'quiz',
    //         eventLabel : tempVal.gaEventLabel
    //       })
    //       break;
    //     case "Q6":
    //       window.dataLayer.push({
    //         event: 'QuestionSubmit',
    //         eventAction : 'Q5-submit',
    //         eventCategory : 'quiz',
    //         eventLabel : tempVal.gaEventLabel
    //       })
    //       break;
    //     default:
    //       break;
    //   }
    //   }
    // }
  },
  created() {
    this.$store.dispatch('INITIALIZE_STORE', this.$quizData)
    this.$store.dispatch('/results/INITIALIZE_RESULTS', this.$quizData.sequences)
  },
  mounted() {
    if (typeof google !== 'undefined') {
      this.initAutoComplete()
    }
    document.querySelector('.question-0 #submit').disabled = true
    document.querySelector('.question-0 #character-count').textContent = 'Please enter a valid street address'
  },
  methods: {
    initAutoComplete() {
      // Initializes auto-complete for the address question
      const characterCount = document.querySelector('.question-0 #character-count')

      const addressField = document.querySelector('.question-0 #user-input')
      const that = this

      addressField.addEventListener('input', () => {
        if (addressField.value.length !== that.addressLength) {
          addressField.classList.add('user-input-error')
          characterCount.classList.add('input-error')
          that.isAddress = false
        }
      })

      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(addressField, {
        componentRestrictions: { country: ['us'] },
        fields: ['formatted_address', 'address_components'],
        types: ['address'],
      })

      this.autocomplete.addListener('place_changed', this.populateAutocompleteField)
    },
    populateAutocompleteField() {
      // Gets the auto-complete dropdown and validates
      const characterCount = document.querySelector('.question-0 #character-count')
      const place = this.autocomplete.getPlace()

      if (place && place.address_components[0].types.includes('street_number')) {
        this.isAddress = true
        this.addressLength = document.querySelector('.question-0 #user-input').value
        if (document.querySelector('.question-0 #user-input').classList.contains('user-input-error')) {
          document.querySelector('.question-0 #user-input').classList.remove('user-input-error')
          this.addressObject = place
          characterCount.classList.remove('input-error')
        }

        return true
      }
      characterCount.classList.add('input-error')
      this.isAddress = false
      return false
    },
  },
}
</script>
